<template>
  <div class="general-settings d-flex flex-column">

    <h4 class="mb-3 text-body-lg font-weight-medium">Time Zone</h4>
    <div class="outline-border mb-11 pb-6 px-5 rounded">
      <UiCheckbox
        v-model="autoDetectTimeZone"
        hide-details
        color="accent-100"
        label="Set time zone automatically"
        class="time-zone-checkbox d-inline-flex mt-2 mb-4"
      />
      <UiSelect
        v-model="userTimeZone"
        :items="timeZones"
        hide-details
        :disabled="autoDetectTimeZone"
        item-text="title"
        item-value="val"
      />
    </div>

    <h4 class="mb-3 text-body-lg font-weight-medium">Dark Mode</h4>
    <div class="d-flex align-center justify-space-between mb-11 py-4 px-5 rounded outline-border">
      <div class="text-body font-weight-semi-bold gray-100--text">Change to Dark mode</div>
      <v-switch
        v-model="userTheme"
        class="ma-0"
        color="accent"
        hide-details
        disabled
      />
    </div>

    <h4 class="mb-3 text-body-lg font-weight-medium">Roles</h4>
    <UiSelect
      v-model="userSettings.default_project_role"
      :items="userRoles"
      clearable
      height="66"
      hide-details
      item-text="title"
      item-value="val"
      class="select-role"
    >
      <template #prepend-inner>
        <div class="user-role-icon d-flex flex-grow-0 align-center justify-center mr-5 rounded gray-30">
          <IconUserEdit width="18" class="white--text"/>
        </div>
        <div>
          <div class="text-body font-weight-semi-bold gray-100--text">Default Role</div>
          <div class="text-body font-weight-light gray-100--text">
            {{ userSettings.default_project_role ? $config.project.userRoleLabels[userSettings.default_project_role] : 'Select role' }}
          </div>
        </div>
      </template>
    </UiSelect>

    <div class="d-flex justify-end pt-12 pb-4" v-if="isChanged">
      <UiBtn
        :disabled="loading"
        :loading="loading"
        width="160"
        color="accent"
        @click="saveSettings"
      >
        Update Settings
      </UiBtn>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {isEqual} from "@/utils/helpers";
import UiCheckbox from "@/components/UI/UiCheckbox";
import UiSelect from "@/components/UI/UiSelect";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "GeneralSettings",
  components: {
    IconUserEdit: () => import('@/components/icons/IconUserEditSolid'),
    UiCheckbox,
    UiSelect,
    UiBtn,
  },
  data() {
    return {
      loading: false,
      userSettings: {},
      autoDetectTimeZone: true,
      userTimeZone: 'Kyiv',
      timeZones: [
        {val: 'Kyiv', title: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'}
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getModal',
    ]),
    isChanged() {
      const {user_theme, default_project_role} = this.getUser.settings;
      const isChanged = !isEqual(this.userSettings, {
        user_theme,
        default_project_role
      });

      this.getModal('pageLeaveModal').dataSavedStatus = !isChanged;
      return isChanged;
    },
    userTheme: {
      get() {
        return Boolean(this.userSettings ? this.userSettings.user_theme : 0);
      },
      set(val) {
        this.setTheme(val);
      }
    },
    userRoles() {
      return Object.values(this.$config.project.userRole).reduce((acc, role) => {
        if (role !== this.$config.project.userRole.projectOwner && role !== this.$config.project.userRole.reviewer) {
          acc.push({val: role, title: this.$config.project.userRoleLabels[role]});
        }
        return acc;
      }, []);
    }
  },
  created() {
    this.cloneUserSettings();
  },
  beforeDestroy() {
    this.setTheme(this.getUser.settings.user_theme);
  },
  methods: {
    setTheme(theme) {
      this.$vuetify.theme.dark = theme;
      localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());

      if (this.$vuetify.theme.dark) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }

      this.userSettings.user_theme = Number(theme);
    },
    async saveSettings() {
      this.loading = true
      try {
        const data = {
          user_theme: this.userSettings.user_theme,
          default_project_role: this.userSettings.default_project_role
        };
        const response = await this.$api.user.saveSettings(this.getUser.id, data);
        await this.$store.dispatch('setUserSetting', response.data);
        this.cloneUserSettings();

        this.$toast.open({
          message: 'Settings updated',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    cloneUserSettings() {
      this.userSettings = JSON.parse(JSON.stringify({
        user_theme: this.getUser.settings.user_theme,
        default_project_role: this.getUser.settings.default_project_role
      }));
    }
  }
}
</script>

<style scoped lang="scss">
.general-settings {
  max-width: 700px;
}

.outline-border {
  border: 1px solid var(--v-gray-30-base);
}

::v-deep .time-zone-checkbox.v-input.ui-checkbox .v-label {
  font-size: 14px;
  color: var(--v-gray-60-base);
}

::v-deep .v-input--switch__track {
  opacity: 1;
}

::v-deep .v-input--switch__thumb {
  border: 1px solid var(--v-gray-30-base);
  box-shadow: 0 0 4.93492px rgba(0, 0, 0, 0.1) !important;
  background-color: #FFFFFF;
}

.user-role-icon {
  width: 34px;
  height: 34px;
}

::v-deep .select-role .ui-select .v-input__slot {
  padding-right: 30px!important;
}

::v-deep .v-expansion-panel-header__icon {
  transition: 0.3s cubic-bezier(.25,.8,.5,1);
}

::v-deep .v-expansion-panel-header--active .v-expansion-panel-header__icon {
  transform: rotate(-180deg);
}
</style>
