<template>
  <div style="font-size: 48px;" class="text-center mx-auto mt-16 py-16">
    Members settings <br> is under construction
  </div>
</template>

<script>
export default {
  name: 'MembersSettings',
}
</script>
