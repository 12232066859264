<template>
  <div class="d-flex flex-column fill-height">
    <v-divider class="mt-14"/>

    <v-container>
      <div class="mt-7 text-body font-weight-semi-bold gray-60--text">
        {{ getUser.first_name || '' }} {{ getUser.last_name || '' }}
      </div>

      <h1 class="mb-6 text-title-1 gray-100--text">Settings</h1>

      <v-tabs
        v-model="tab"
        slider-color="accent"
        slider-size="3"
        height="32"
        class="tabs mb-10"
      >
        <v-tab
          v-for="page in pages"
          :key="page.routeName"
          :to="{name: page.routeName}"
          class="tab relative text-captions-1 text-capitalize"
        >
          {{ page.label }}
        </v-tab>
      </v-tabs>

      <component :is="$route.name"/>

    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GeneralSettings from "@/views/settings/GeneralSettings";
import AuthenticationSettings from "@/views/settings/AuthenticationSettings";
import MembersSettings from "@/views/settings/MembersSettings";

export default {
  name: 'SettingsPage',
  components: {
    GeneralSettings,
    AuthenticationSettings,
    MembersSettings,
  },
  data() {
    return {
      tab: null,
      pages: [
        {label: 'General Settings', routeName: 'GeneralSettings'},
        {label: 'Authentication', routeName: 'AuthenticationSettings'},
        {label: 'Members', routeName: 'MembersSettings'},
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ])
  },
}
</script>

<style scoped lang="scss">
:deep(.v-slide-group__wrapper) {
  overflow: initial;
  contain: initial;
}

:deep(.tabs) {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none!important;
  }
}

.tab {
  height: 30px;
  padding: 0 44px 0 16px;
  color: var(--v-gray-30-base) !important;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 7px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 3px;
    background-color: var(--v-gray-30-base);
  }

  &.v-tab--active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    color: var(--v-gray-60-base) !important;
  }
}
</style>
